import axios from 'axios';
import { isEmpty, isNil } from 'ramda';

const API_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

export const SERVICED_COUNTRY_ISO_CODE = 'pl';

const addressWithRemovedLocal = ({ address }) => !isNil(address)
    ? address.split('/')[0]
        .replaceAll('.', ' ')
        .replaceAll('  ', ' ')
    : '';

export const findLocationOnAdd = async (placeData) => !isEmpty(placeData.geo_lat) && !isEmpty(placeData.geo_long)
    ? placeData
    : findLocation(placeData);

export const findLocation = async (placeData) => {
    if (isNil(placeData.name) && isNil(placeData.id)) {
        return null;
    }

    if (!isEmpty(placeData.id) && isEmpty(placeData.name)) {
        return placeData;
    }

    try {
        const result = await axios.get(
            `${API_URL}${addressWithRemovedLocal(placeData)},${placeData.postal_code},${placeData.city}.json`, {
                params: {
                    access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
                    postcode: placeData.postal_code,
                    country: SERVICED_COUNTRY_ISO_CODE
                }
            }
        );

        const { data } = result;

        if (isEmpty(data.features)) {
            console.error('No geo location results');
            return null;
        }

        // eslint-disable-next-line camelcase
        const { geo_lat } = placeData;

        return isNil(geo_lat) || isEmpty(geo_lat)
            ? {
                ...placeData,
                geo_lat: parseFloat(data.features[0].center[1]),
                geo_long: parseFloat(data.features[0].center[0])
            }
            : {
                geo_lat: parseFloat(data.features[0].center[1]),
                geo_long: parseFloat(data.features[0].center[0]),
                ...placeData
            };
    }
    catch (e) {
        return null;
    }
};
