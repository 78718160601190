import { fromJS } from 'immutable';
import { nanoid } from 'nanoid';
import { isEmpty } from 'ramda';
import {
    CHANGE_PAGE, handleSortChangeType,
    REMOVE_FROM_CHANGELOG,
    SET_CSV_CHANGELOG,
    SET_DATA, SET_NEW_TABLE_DATA, SET_SORT_OPTIONS, SORT_ORDERS,
    UPDATE_CHANGELOG,
    SET_FILTER_VALUE
} from './const';
import { sortData } from '../../../dataConfiguration';
import { ID_LENGTH } from '../../../const';
import { TABLE_ITEM_ACTION } from '../components/const';

const initialState = fromJS({
    data: [],
    changelog: [],
    itemToEdit: {},
    sortOrderKey: 'last_update',
    sortOrder: SORT_ORDERS.ASCENDING,
    currentPage: 1,
    filterValue: ''
});

export const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA: {
            const { data } = action;
            const dataWithOperation = data.map((i) => ({ operation: 'NONE', data: i }));

            return initialState
                .set('data', fromJS(dataWithOperation, sortData));
        }
        case SET_CSV_CHANGELOG: {
            return state.set('changelog', fromJS(action.CSVChangelog));
        }
        case SET_NEW_TABLE_DATA: {
            return state.set('data', fromJS(action.newTableData));
        }
        case UPDATE_CHANGELOG: {
            const { tableItem, operation } = action;
            const data = (isEmpty(tableItem.id) ? { ...tableItem, id: nanoid(ID_LENGTH) } : { ...tableItem });
            const newChangeLogObject = { operation, data };

            return state
                .update('changelog', (changelogList) => (changelogList
                    .filter((changelog) => changelog.get('data').get('id') !== newChangeLogObject.data.id))
                    .push(fromJS(newChangeLogObject, sortData)));
        }
        case REMOVE_FROM_CHANGELOG: {
            const { id } = action;
            return state
                .update('changelog', (changelogList) => (changelogList
                    .filter((changelog) => changelog.get('data').get('id') !== id)));
        }
        case TABLE_ITEM_ACTION: {
            const { tableItem, isOpen, panelType } = action;
            const itemToEdit = { data: tableItem, isOpen, panelType };
            return state.set('itemToEdit', fromJS(itemToEdit));
        }
        case CHANGE_PAGE: {
            const { pageNumber } = action;
            return state.set('currentPage', fromJS(pageNumber));
        }
        case SET_SORT_OPTIONS: {
            const { sortOrderKey, changeType } = action;
            return state
                .set('sortOrderKey', sortOrderKey)
                .set('sortOrder',
                    handleSortChangeType(
                        changeType,
                        state.get('sortOrder')
                    ));
        }
        case SET_FILTER_VALUE: {
            return state.set('filterValue', action.filterValue);
        }
        default:
            return state;
    }
};
