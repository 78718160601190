import { fromJS } from 'immutable';
import { FAIL_USER_LOGIN, SET_USER_ID, USER_LOGIN_STATUSES } from './const';

const initialState = fromJS({
    id: '',
    status: USER_LOGIN_STATUSES.NONE
});

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_ID: {
            const { id } = action;
            return state
                .set('id', id)
                .set('status', USER_LOGIN_STATUSES.SUCCEED);
        }
        case FAIL_USER_LOGIN:
            return state.set('status', USER_LOGIN_STATUSES.FAILED);
        default:
            return state;
    }
};
