import React from 'react';
import { Tr, Td } from '@chakra-ui/table';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { OPERATION_COLORS } from '../../../../const';
import { TableItemActionButton } from '../../components';
import { columnNamesOrderedSelector } from '../selectors';
import { timestampsToData } from '../const';
import { PinImage } from './PinImage';
import pin from '../../../../assets/icons/pin.png';
import { MapPopover } from './MapPopover';

export const RowDisplay = ({ operation, stripes, rowData }) => {
    const backgroundColor = getBackgroundColor(operation, stripes);
    const columnNames = useSelector(columnNamesOrderedSelector);

    const renderColumnData = (rowData, columnName) => {
        const thisColumnData = rowData.getIn(['data', columnName]);
        switch (columnName) {
            case 'last_update':
                return timestampsToData(thisColumnData);
            case 'custom_pin':
                return <PinImage src={isNil(thisColumnData) ? pin : thisColumnData} />;
            case 'address': {
                const data = rowData.get('data');
                return (
                    <MapPopover
                        fullName={data.get('canonical_name')}
                        lat={data.get('geo_lat')}
                        long={data.get('geo_long')}
                        pinSrc={data.get('custom_pin')}
                        columnName={thisColumnData}
                    />
                );
            }
            default:
                return thisColumnData;
        }
    };

    return (
        <Tr backgroundColor={backgroundColor}>
            <Td>
                <TableItemActionButton tableItem={rowData} panelType="Edytuj" />
            </Td>
            {columnNames.map(
                (columnName) => (
                    <Td
                        width="10%"
                        maxWidth="10%"
                        key={columnName}
                    >
                        {renderColumnData(rowData, columnName)}
                    </Td>
                )
            )}
        </Tr>
    );
};

const getBackgroundColor = (operation, stripes) => `${OPERATION_COLORS[operation]}.${stripes === 0 ? 300 : 200}`;

RowDisplay.propTypes = {
    operation: PropTypes.string.isRequired,
    stripes: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired
};
