import { createSelector } from 'reselect';
import {
    prop, map, range, isEmpty, defaultTo
} from 'ramda';
import { List, fromJS } from 'immutable';
import {
    TABLE_REDUCER_NAME, NUMBER_OF_ITEMS, KEY_ORDERS, SORT_ORDERS, KEY_FORM_ORDERS, tableElementToCSVValue
} from './const';

const getTableReducerState = prop(TABLE_REDUCER_NAME);
const editedItemsSelector = createSelector(getTableReducerState, (tableState) => tableState.get('changelog').filter((d) => d.get('operation') === 'EDIT'));
const addedItemsSelector = createSelector(getTableReducerState, (tableState) => tableState.get('changelog').filter((d) => d.get('operation') === 'ADD'));
const deletedItemsSelector = createSelector(getTableReducerState, (tableState) => tableState.get('changelog').filter((d) => d.get('operation') === 'DELETE'));

export const dataSelector = createSelector(getTableReducerState, editedItemsSelector, addedItemsSelector, deletedItemsSelector, (tableState, editedItems, addedItems, deletedItems) => {
    const data = tableState.get('data');

    const noEditedItems = data.filter((d) => !editedItems.some((i) => d.get('data').get('id') === i.get('data').get('id')));
    const noDeletedItems = noEditedItems.filter((d) => !deletedItems.some((i) => d.get('data').get('id') === i.get('data').get('id')));
    const combinedData = addedItems.concat(editedItems, deletedItems, noDeletedItems);

    return combinedData;
});

export const exportableDataSelector = createSelector(
    dataSelector,
    (data) => data.map((element) => tableElementToCSVValue(element.get('data')))
);

export const exportableAllDataSelector = createSelector(
    dataSelector,
    (data) => data.map((element) => tableElementToCSVValue(element.get('data'), true))
);

export const filterValueSelector = createSelector(
    getTableReducerState,
    (tableState) => tableState.get('filterValue')
);

export const filteredDataSelector = createSelector(
    dataSelector,
    filterValueSelector,
    (data, filterValue) => isEmpty(filterValue)
        ? data
        : defaultTo(
            [],
            data.filter(
                (dataItem) => dataItem
                    .getIn(['data', 'canonical_name'])
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())
            )
        )
);

export const sortOrderKeySelector = createSelector(getTableReducerState,
    (tableState) => tableState.get('sortOrderKey'));

export const sortOrderSelector = createSelector(getTableReducerState,
    (tableState) => tableState.get('sortOrder'));

export const sortedDataSelector = createSelector(
    filteredDataSelector,
    sortOrderKeySelector,
    sortOrderSelector,
    (data, orderKey, sortOrder) => {
        const sortedData = data.sortBy(
            (dataItem) => {
                const dataItemField = dataItem.getIn(['data', orderKey]);
                if (typeof dataItemField === 'string') {
                    return dataItemField.toLowerCase();
                }
                return dataItemField;
            }
        );

        if (sortOrder === SORT_ORDERS.ASCENDING) {
            return sortedData.reverse();
        }
        return sortedData;
    }
);

export const changelogSelector = createSelector(getTableReducerState, (tableState) => tableState.get('changelog'));

export const columnNamesSelector = createSelector(dataSelector, (data) => (List(data.get(0)?.get('data').keys()) ?? List()));

export const columnNamesOrderedSelector = createSelector(
    columnNamesSelector,
    (column) => column.sort(
        (a, b) => prop(a, KEY_ORDERS) - prop(b, KEY_ORDERS)
    )
);

export const columnFormNamesOrderedSelector = createSelector(
    columnNamesSelector,
    (column) => column.sort(
        (a, b) => prop(a, KEY_FORM_ORDERS) - prop(b, KEY_FORM_ORDERS)
    )
);

export const rowCounterSelector = createSelector(sortedDataSelector, (data) => data.size);

export const itemToEditSelector = createSelector(getTableReducerState, (tableState) => tableState.get('itemToEdit').get('data'));

export const isOpenModalSelector = createSelector(getTableReducerState, (tableState) => tableState.get('itemToEdit').get('isOpen'));

export const panelTypeSelector = createSelector(getTableReducerState, (tableState) => tableState.get('itemToEdit').get('panelType'));

export const currentPageSelector = createSelector(getTableReducerState, (tableState) => tableState.get('currentPage'));

export const numberOfPagesSelector = createSelector(rowCounterSelector, (rowsCounter) => {
    const numberOfPages = Math.ceil(rowsCounter / NUMBER_OF_ITEMS);
    return numberOfPages;
});

export const pagesSelector = createSelector(sortedDataSelector, currentPageSelector, rowCounterSelector, (data, currentPage, rowCounter) => {
    const from = (currentPage - 1) * NUMBER_OF_ITEMS;
    const to = ((from + NUMBER_OF_ITEMS) > rowCounter ? rowCounter : (from + NUMBER_OF_ITEMS));

    const pages = [];
    map((rowIndex) => pages.push(data.get(rowIndex)), range(from, to));

    return fromJS(pages);
});
