import { ofType } from 'redux-observable';
import {
    map, from, switchMap, flatMap
} from 'rxjs';
import { defaultTo } from 'ramda';
import {
    DEFAULT_EMPTY_DB_RECORD, SELECT_TABLE, tableItemPropsToDefaultValues, UPDATE_CHANGELOG_REQUEST
} from './const';
import { getData } from './api';
import { setData, updateChangelog } from './actions';
import { tokenSelector } from '../../../token/selectors';
import { findLocationOnAdd } from '../../../mapbox/api';

export const tableEpic = (action$, state$) => action$.pipe(
    ofType(SELECT_TABLE),
    map(() => tokenSelector(state$.value)),
    switchMap((token) => from(getData(token)).pipe(map(({ data }) => setData(data))))
);

export const fetchGeolocationOnAddEpic = (action$) => action$.pipe(
    ofType(UPDATE_CHANGELOG_REQUEST),
    flatMap(({ tableItem, operation }) => from(findLocationOnAdd(tableItem)).pipe(
        map((newTableItem) => updateChangelog(
            tableItemPropsToDefaultValues(defaultTo(DEFAULT_EMPTY_DB_RECORD, newTableItem)),
            operation
        ))
    ))
);
