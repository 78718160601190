import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const PinImage = styled.img`
    transform: translate3d(16px, 0, 0);
    max-height: 25px;
    max-width: 29px;
    aspect-ratio: 0.64102564102;

    ${ifProp('isOnMap', css`
        position: absolute;
        transform: translate3d(-12.5px, -39px, 0);
    `)}
`;

PinImage.defaultProps = {
    isOnMap: false
};
