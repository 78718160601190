import {
    filter, includes, map as RMap, prop, tail
} from 'ramda';
import { ofType } from 'redux-observable';
import {
    catchError, flatMap,
    forkJoin, from, map, mergeMap, of
} from 'rxjs';
import { post } from 'axios';
import {
    createCSVChangelog, GET_TABLE_LIST, POST_CHANGELOG_REQUEST, PRODUCE_LOCATED_PLACES_REQUEST
} from './const';
import { getTableList } from './api';
import {
    getTableListRequest, saveLocatedPlaces, setChangelogSuccessStatus, setCSVChangelog, setErrorsMessages, setTableList
} from './actions';
import { findLocation } from '../../../mapbox/api';
import { setNewTableData } from '../table/actions';
import { parseCSV } from '../table/const';

export const getLocalizatorsEpic = (action$) => action$.pipe(
    ofType(GET_TABLE_LIST),
    map(() => ({ tableList: getTableList() })),
    map(({ tableList }) => setTableList(tableList))
);

export const produceLocatedPlacesEpic = (action$, state$) => action$.pipe(
    ofType(PRODUCE_LOCATED_PLACES_REQUEST),
    map(({ listOfElements }) => RMap(parseCSV, tail(listOfElements))),
    mergeMap((listOfElements) => forkJoin(...RMap((place) => findLocation(place), listOfElements))),
    map((placesWithGeoLocation) => filter((place) => place !== null, placesWithGeoLocation)),
    flatMap((placesWithGeoLocation) => {
        const currentTable = state$.value.Table.get('data').toJS();
        const CSVChangelog = createCSVChangelog(placesWithGeoLocation, currentTable);
        const idsToRemoveFromTable = RMap((changelogData) => prop('id', prop('data', changelogData)), CSVChangelog);
        const newTable = filter(
            (tableElement) => !includes(tableElement.data.id, idsToRemoveFromTable),
            currentTable
        );

        return of(
            setNewTableData(newTable),
            setCSVChangelog(CSVChangelog),
            saveLocatedPlaces(placesWithGeoLocation)
        );
    }),
    catchError((error) => {
        console.error(error);
        // geolocationFailedAction();
    })
);

export const postChangelogEpic = (action$) => action$.pipe(
    ofType(POST_CHANGELOG_REQUEST),
    map((data) => ({ jsonData: JSON.stringify(data.changelog), ...data })),
    flatMap(
        ({ token, jsonData }) => from(
            post(
                `${process.env.REACT_APP_PANELS_API}${token}`,
                jsonData,
                { withCredentials: true }
            )
        ).pipe(
            mergeMap(({ data }) => data.status === 'OK'
                ? of(
                    setChangelogSuccessStatus(),
                    getTableListRequest()
                )
                : of(setErrorsMessages(data.errors),
                    getTableListRequest()))
        )
    ),
    catchError((error) => {
        console.error(error);
        return of({ type: 'FAILED_POST_CHANGELOG' });
    })

);
