import { fromJS } from 'immutable';
import { SELECT_TABLE } from '../table/const';
import {
    FETCH_STATUS,
    POST_CHANGELOG_REQUEST,
    PRODUCE_LOCATED_PLACES_REQUEST,
    SAVE_LOCATED_PLACES,
    SET_CHANGELOG_SUCCESS_STATUS,
    SET_TABLE_LIST
} from './const';

const initialState = fromJS({
    table: {},
    tableList: [],
    fetchStatus: FETCH_STATUS.DONE,
    fetchedPlaces: []
});

export const navigationPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_TABLE: {
            const { table } = action;
            return state.set('table', fromJS(table));
        }
        case SET_TABLE_LIST: {
            const { tableList } = action;
            return state.set('tableList', fromJS(tableList));
        }
        case PRODUCE_LOCATED_PLACES_REQUEST:
        case POST_CHANGELOG_REQUEST:
            return state.set('fetchStatus', FETCH_STATUS.IN_PROGRESS);
        case SAVE_LOCATED_PLACES:
            return state
                .set('fetchStatus', FETCH_STATUS.DONE)
                .set('fetchedPlaces', fromJS(action.placesWithGeoLocation));
        case SET_CHANGELOG_SUCCESS_STATUS:
            return state.set('fetchStatus', FETCH_STATUS.DONE);
        default:
            return state;
    }
};
