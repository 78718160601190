import { ofType } from 'redux-observable';
import {
    catchError, flatMap, from, mergeMap, of
} from 'rxjs';
import { post } from 'axios';
import { REQUEST_USER_LOGIN } from './const';
import { failUserLogin, setUserId } from './actions';

export const requestUserLoginEpic = (action$) => action$.pipe(
    ofType(REQUEST_USER_LOGIN),
    flatMap(
        ({ username, password }) => from(post(`${process.env.REACT_APP_LOGIN_API}`, { username, password }, { withCredentials: true })).pipe(
            mergeMap((response) => of(setUserId(response.data.user_id))),
            catchError((error) => {
                console.error(error);
                return of(failUserLogin());
            })
        )
    )
);
