export const CHANGELOG_ACTIONS = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
};

export const ID_LENGTH = 10;

export const OPERATION_COLORS = {
    ADD: 'green',
    EDIT: 'yellow',
    DELETE: 'red',
    NONE: 'gray'
};
