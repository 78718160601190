import React from 'react';
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FormikInput } from './formikInput';
import { columnFormNamesOrderedSelector } from '../../../table/selectors';
import { getInitialValues } from './logic';
import { ID } from '../../const';

export const TableItemDialog = ({
    tableItem, onClose, panelType, onSave, deleteDisabled, onDelete
}) => {
    const columnNames = useSelector(columnFormNamesOrderedSelector);
    const initialValues = getInitialValues(tableItem, columnNames);
    const filteredColumnNames = columnNames.filter((columnName) => columnName !== ID);

    const onSubmit = (values) => {
        onSave(values);
        onClose();
    };

    const columnGreyoutValue = (id) => {
        if (id < 8) {
            return 0;
        }
        if (id < 10) {
            return 300;
        }
        return 500;
    };

    return (
        <>
            <ModalOverlay />

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                <Form>
                    <ModalContent>
                        <ModalHeader mb="3">
                            {`${panelType} panel`}
                        </ModalHeader>

                        <ModalCloseButton />

                        <ModalBody>
                            {filteredColumnNames.map(
                                (columnName, id) => (
                                    <FormikInput
                                        key={columnName}
                                        name={columnName}
                                        backgroundColorValue={columnGreyoutValue(id)}
                                    />
                                )
                            )}
                        </ModalBody>

                        <ModalFooter display="flex" justifyContent="space-between">
                            <Box>
                                <Button colorScheme="red" mr="3" disabled={deleteDisabled} onClick={onDelete}>Delete</Button>
                            </Box>
                            <Box>
                                <Button variant="outline" color="red.500" mr="3" onClick={onClose}>Close</Button>
                                <Button colorScheme="whatsapp" type="submit">Save</Button>
                            </Box>
                        </ModalFooter>
                    </ModalContent>
                </Form>
            </Formik>
        </>
    );
};

TableItemDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    tableItem: PropTypes.object,
    panelType: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    deleteDisabled: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired
};

TableItemDialog.defaultProps = {
    tableItem: undefined,
    panelType: ''
};

