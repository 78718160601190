import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@chakra-ui/button';
import { TableItemAction } from '../actions';

export const TableItemActionButton = ({ tableItem, panelType }) => {
    const dispatch = useDispatch();

    const onOpen = () => {
        dispatch(TableItemAction(tableItem, true, panelType));
    };

    return (
        <Button
            marginRight="8px"
            onClick={onOpen}
        >
            {panelType}
        </Button>
    );
};

TableItemActionButton.propTypes = {
    tableItem: PropTypes.object,
    panelType: PropTypes.string.isRequired
};

TableItemActionButton.defaultProps = {
    tableItem: undefined
};
