export const INPUT_TYPES = {
    USERNAME: 'username',
    PASSWORD: 'password'
};

export const REQUEST_USER_LOGIN = 'loginPanel/REQUEST_USER_LOGIN';
export const SET_USER_ID = 'loginPanel/SET_USER_ID';
export const FAIL_USER_LOGIN = 'loginPanel/FAIL_USER_LOGIN';

export const LOGIN_REDUCER_NAME = 'loginPanel';

export const USER_LOGIN_STATUSES = {
    NONE: 'NONE',
    FAILED: 'FAILED',
    SUCCEED: 'SUCCEED'
};
