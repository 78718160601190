import { fromJS } from 'immutable';
import { SELECT_TABLE } from '../containers/mainPage/table/const';

const initialState = fromJS({
    token: ''
});

export const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_TABLE: {
            const { table } = action;
            return state.set('token', fromJS(table.get('token')));
        }
        default:
            return state;
    }
};
