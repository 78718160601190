import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { MainPage } from './containers/mainPage';
import { Login } from './containers/loginPanel';
import { getLoginStatus } from './containers/loginPanel/selectors';
import { USER_LOGIN_STATUSES } from './containers/loginPanel/const';

export const App = () => {
    const status = useSelector(getLoginStatus);

    if (status !== USER_LOGIN_STATUSES.SUCCEED) {
        return (
            <ChakraProvider>
                <Login />
            </ChakraProvider>
        );
    }
    return (
        <ChakraProvider>
            <MainPage />
        </ChakraProvider>
    );
};
