import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, Container, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginFormikInput } from './loginFormikInputs';
import { requestUserLogin } from '../actions';
import { getLoginStatus } from '../selectors';
import { USER_LOGIN_STATUSES } from '../const';

export const LoginDialog = () => {
    const dispatch = useDispatch();
    const status = useSelector(getLoginStatus);

    const initialValues = {
        username: '',
        password: ''
    };

    const validationSchema = Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required')
    });

    const onSubmit = (values) => dispatch(requestUserLogin(values));

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {
                (formik) => (
                    <Form>
                        <Container m="4" centerContent>
                            <LoginFormikInput type="username" label="Nazwa użytkownika:" name="username" isValid={!formik.isValid} />
                            <LoginFormikInput type="password" label="Hasło:" name="password" isValid={!formik.isValid} />
                            {status === USER_LOGIN_STATUSES.FAILED && (
                                <Text
                                    color="red.400"
                                    fontSize="12px"
                                >
                                    Zła nazwa lub hasło użytkownika
                                </Text>
                            )}
                            <Button mt="6" type="submit" disabled={!formik.isValid}>Zaloguj</Button>
                        </Container>
                    </Form>
                )
            }
        </Formik>
    );
};
