import React from 'react';
import { Modal } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TableItemDialog } from './tableItemDialog';
import { itemToEditSelector, panelTypeSelector } from '../../table/selectors';
import { TableItemAction } from '../actions';
import { CHANGELOG_ACTIONS } from '../../../../const';
import { updateChangelog, removeFromChangelog, updateChangelogRequest } from '../../table/actions';
import { parseLatLongString, setLastUpdateDate } from '../const';

export const TableItemPanel = ({ isOpen }) => {
    const dispatch = useDispatch();
    const itemToEdit = useSelector(itemToEditSelector);
    const panelType = useSelector(panelTypeSelector);
    const deleteDisabled = (panelType === 'Dodaj');

    const onClose = () => {
        dispatch(TableItemAction(undefined, false, ''));
    };

    const onSave = (values) => {
        const data = { ...values };

        const dataWithGeolocationAsNumbers = {
            ...data,
            geo_lat: parseLatLongString(data.geo_lat),
            geo_long: parseLatLongString(data.geo_long)
        };

        const currentOperation = ((itemToEdit === undefined || itemToEdit.get('operation') === CHANGELOG_ACTIONS.ADD) ? CHANGELOG_ACTIONS.ADD : CHANGELOG_ACTIONS.EDIT);

        dispatch(updateChangelogRequest(dataWithGeolocationAsNumbers, currentOperation));
        onClose();
    };

    const onDelete = () => {
        if (itemToEdit.get('operation') === CHANGELOG_ACTIONS.ADD) {
            dispatch(removeFromChangelog(itemToEdit.get('data').get('id')));
        }
        else {
            dispatch(updateChangelog(setLastUpdateDate(itemToEdit.get('data').toJS()), CHANGELOG_ACTIONS.DELETE));
        }
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
            <TableItemDialog tableItem={itemToEdit} onClose={onClose} panelType={panelType} onSave={onSave} deleteDisabled={deleteDisabled} onDelete={onDelete} />
        </Modal>
    );
};

TableItemPanel.propTypes = {
    isOpen: PropTypes.bool
};

TableItemPanel.defaultProps = {
    isOpen: false
};
