import React from 'react';
import { Container, Spinner } from '@chakra-ui/react';

export const Loader = () => (
    <Container
        d="flex"
        justifyContent="center"
        alignItems="center"
        h="90vh"
    >
        <Spinner
            thickness="2px"
            speed="0.80s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
        />
    </Container>
);
