import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { LOGIN_REDUCER_NAME } from './const';

const getLoginPanelReducerState = prop(LOGIN_REDUCER_NAME);

export const idSelector = createSelector(
    getLoginPanelReducerState,
    (loginPanelState) => loginPanelState.get('id')
);

export const getLoginStatus = createSelector(
    getLoginPanelReducerState,
    (loginPanelState) => loginPanelState.get('status')
);
