import { combineEpics } from 'redux-observable';
import { fetchGeolocationOnAddEpic, tableEpic } from './containers/mainPage/table/epics';
import {
    getLocalizatorsEpic,
    postChangelogEpic,
    produceLocatedPlacesEpic
} from './containers/mainPage/navigationPanel/epics';
import { requestUserLoginEpic } from './containers/loginPanel/epics';

export const rootEpic = combineEpics(
    getLocalizatorsEpic,
    tableEpic,
    produceLocatedPlacesEpic,
    postChangelogEpic,
    requestUserLoginEpic,
    fetchGeolocationOnAddEpic
);
