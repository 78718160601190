import { isEmpty, isNil } from 'ramda';

export const TABLE_ITEM_ACTION = 'TableItemActionButton/TABLE_ITEM_ACTION';
export const ID = 'id';

export const setLastUpdateDate = (tableItem) => ({
    ...tableItem,
    last_update: Math.floor(Date.now() / 1000)
});

export const parseLatLongString = (geoParam) => isNil(geoParam) || isEmpty(geoParam) ? geoParam : parseFloat(geoParam);
