import { get, post } from 'axios';

export const getData = async (token) => {
    const response = await get(`${process.env.REACT_APP_PANELS_API}${token}`);

    if (!response.status) {
        console.error('Error occured while fetching the data');
    }
    return response.data;
};

export const postChangeLog = async (token, changelog) => {
    const json = JSON.stringify(changelog);
    const response = post(`${process.env.REACT_APP_BACKEND_API}${token}`, json);
    return response.data;
};
