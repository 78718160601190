import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import { TableItem } from './table';
import { NavigationPanel } from './navigationPanel';
import { Footer } from './components';
import { tableSelector } from './navigationPanel/selectors';
import { TablePagination } from './table/tablePagination';
import { numberOfPagesSelector } from './table/selectors';

export const MainPage = () => {
    const currentPage = useSelector(tableSelector).get('name');
    const numberOfPages = useSelector(numberOfPagesSelector);

    return (
        <Flex maxWidth="100vw" flexDirection="column" height="100%">
            <Flex>
                <NavigationPanel />
            </Flex>
            <Flex height="calc(100% - 172px)" overflowX="auto">
                <TableItem />
            </Flex>
            <TablePagination numberOfPages={numberOfPages} />
            <Flex>
                <Footer currentPage={currentPage} />
            </Flex>
        </Flex>
    );
};
