import React from 'react';
import {
    Table, Tbody, Thead, Tr, Th,
    Container, Text, Flex
} from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';
import {
    isOpenModalSelector,
    pagesSelector, columnNamesOrderedSelector, sortOrderSelector, sortOrderKeySelector, dataSelector
} from './selectors';
import { Loader } from '../components';
import { RowDisplay } from './rowDisplay';
import { TableItemPanel } from '../components/TableItemPanel';
import { COLUMN_TRANSLATION_TABLE, SORT_CHANGE_TYPES, SORT_ORDERS } from './const';
import { setSortOptions } from './actions';

export const TableItem = () => {
    const dispatch = useDispatch();
    const data = useSelector(dataSelector);
    const columnNames = useSelector(columnNamesOrderedSelector);
    const isOpen = useSelector(isOpenModalSelector);
    const pages = useSelector(pagesSelector);
    const sortOrderKey = useSelector(sortOrderKeySelector);
    const sortOrder = useSelector(sortOrderSelector);

    const renderOrderArrow = (columnName) => {
        if (columnName === sortOrderKey) {
            if (sortOrder === SORT_ORDERS.ASCENDING) {
                return (
                    <Flex w={6} h={6} alignSelf="center">
                        <ArrowDownIcon w={4} h={4} />
                    </Flex>
                );
            }
            return (
                <Flex w={6} h={6} alignSelf="center">
                    <ArrowUpIcon w={4} h={4} />
                </Flex>
            );
        }
        return <Flex w={6} h={6} alignSelf="center" visibility="hidden" />;
    };

    if (data.size === 0) {
        return <Loader />;
    }

    const getSortOrderChangeType = (columnName) => columnName === sortOrderKey
        ? SORT_CHANGE_TYPES.SWAP
        : SORT_CHANGE_TYPES.SET;

    return (
        <Container maxW="100vw" maxH="2xl">
            <TableItemPanel isOpen={isOpen} />
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th />
                        {columnNames.map(
                            (columnName) => (
                                <Th
                                    width="10%"
                                    maxWidth="10%"
                                    key={columnName}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => dispatch(setSortOptions(columnName, getSortOrderChangeType(columnName)))}
                                >
                                    <Flex flexDirection="row" userSelect="none" alignItems="center">
                                        {renderOrderArrow(columnName)}
                                        <Text>{prop(columnName, COLUMN_TRANSLATION_TABLE)}</Text>
                                    </Flex>
                                </Th>
                            )
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {pages.map(
                        (rowData, index) => (
                            <RowDisplay
                                operation={rowData.get('operation')}
                                stripes={index % 2}
                                rowData={rowData}
                                key={rowData.getIn(['data', 'id'])}
                            />
                        )
                    )}
                </Tbody>
            </Table>
        </Container>
    );
};
