import React, { useState } from 'react';
import {
    Box, Text, Input, InputGroup, InputRightElement, Button
} from '@chakra-ui/react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { INPUT_TYPES } from '../../const';

export const LoginFormikInput = ({
    type, label, name, isValid
}) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const [field] = useField({ name });

    if (type === INPUT_TYPES.USERNAME) {
        return (
            <Box m="4" width="20rem">
                <Text textTransform="uppercase" fontSize="sm" fontWeight="semibold" color="gray.500">
                    {label}
                </Text>
                <Input
                    autoComplete="on"
                    isInvalid={isValid}
                    errorBorderColor="crimson"
                    placeholder="Wprowadź nazwę użytkownika"
                    name={name}
                    onChange={field.onChange}
                />
            </Box>
        );
    }

    return (
        <Box m="4" width="20rem">
            <Text textTransform="uppercase" fontSize="sm" fontWeight="semibold" color="gray.500">
                Password
            </Text>

            <InputGroup>
                <Input
                    autoComplete="on"
                    isInvalid={isValid}
                    errorBorderColor="crimson"
                    pr="4.5rem"
                    type={show ? 'text' : 'password'}
                    placeholder="Wprowadź hasło"
                    name="password"
                    onChange={field.onChange}
                />
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Ukryj' : 'Pokaż'}
                    </Button>
                </InputRightElement>
            </InputGroup>
        </Box>
    );
};

LoginFormikInput.propTypes = {
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired
};

