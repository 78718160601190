import { Container, Text } from '@chakra-ui/react';
import React from 'react';
import { LoginDialog } from './loginDialog';

export const Login = () => (
    <Container
        maxW="container.md"
        centerContent
        d="flex"
        justifyContent="center"
        flexDirection="column"
        boxShadow="2xl"
        h="60vh"
        marginTop="6rem"
        rounded="md"
    >
        <Text
            style={{ marginTop: '-2rem' }}
            color="gray"
            fontSize="6xl"
        >
            Panel administracyjny
        </Text>
        <LoginDialog />
    </Container>
);
