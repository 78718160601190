import React from 'react';
import { Container, Text, Input } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { isEmpty, isNil, prop } from 'ramda';
import { COLUMN_TRANSLATION_TABLE } from '../../../../table/const';
import pin from '../../../../../../assets/icons/pin.png';
import { FormikPinImage } from './FormikPinImage';
import { MapBoxDisplay } from '../../../../table/rowDisplay/MapBoxDisplay';
import { FormikMapWrapper } from './FormikMapWrapper';

export const FormikInput = ({ name, backgroundColorValue }) => {
    const [{ value, onChange }] = useField({ name });
    const [pinField] = useField('custom_pin');
    const [latField] = useField('geo_lat');

    const renderAdditionalComponent = () => {
        switch (name) {
            case 'custom_pin':
                return <FormikPinImage src={isNil(value) || isEmpty(value) ? pin : value} />;
            case 'geo_long':
                return isEmpty(value) || isEmpty(latField.value)
                    ? null
                    : (
                        <FormikMapWrapper>
                            <MapBoxDisplay
                                long={parseFloat(value)}
                                lat={parseFloat(latField.value)}
                                pinSrc={pinField.value}
                            />
                        </FormikMapWrapper>
                    );
            default:
                return null;
        }
    };

    return (
        <Container mb="8">
            <Text textTransform="uppercase" fontSize="sm" fontWeight="semibold" color="gray.500" key={`column-${name}`}>
                {prop(name, COLUMN_TRANSLATION_TABLE)}
            </Text>
            <Input
                value={value}
                onChange={onChange}
                name={name}
                backgroundColor={`gray.${backgroundColorValue}`}
            />
            {renderAdditionalComponent()}
        </Container>
    );
};

FormikInput.propTypes = {
    name: PropTypes.string.isRequired,
    backgroundColorValue: PropTypes.number
};

FormikInput.defaultProps = {
    backgroundColorValue: 500
};
