import React, {
    useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';
import {
    Container, Flex, Button, Input
} from '@chakra-ui/react';
import { isNil } from 'ramda';

import { CSVDownloader } from 'react-papaparse';
import { selectTable, setFilterValue } from '../table/actions';
import { getTableListRequest, postChangeLogRequest, requestPlacesLocalizationProduction } from './actions';
import { fetchStatusSelector, tableListSelector } from './selectors';
import { TableName } from './TableName/index';
import { Loader, TableItemActionButton } from '../components';
import { FETCH_STATUS, parseGeoLocation } from './const';
import { tokenSelector } from '../../../token/selectors';
import {
    changelogSelector, exportableAllDataSelector, exportableDataSelector, filterValueSelector
} from '../table/selectors';

export const NavigationPanel = () => {
    const dispatch = useDispatch();
    const uploadCSVButtonRef = useRef();
    const [selectedFile, setSelectedFile] = useState();
    const tableList = useSelector(tableListSelector);
    const fetchStatus = useSelector(fetchStatusSelector);
    const exportableData = useSelector(exportableDataSelector);
    const exportableAllData = useSelector(exportableAllDataSelector);

    const changelog = useSelector(changelogSelector);
    const token = useSelector(tokenSelector);
    const filterValue = useSelector(filterValueSelector);

    useEffect(() => {
        dispatch(getTableListRequest());
    }, [dispatch]);

    useEffect(() => {
        if (tableList.size !== 0) {
            dispatch(selectTable(tableList.get(0)));
        }
    }, [dispatch, tableList]);

    useEffect(() => {
        if (!isNil(selectedFile)) {
            Papa.parse(selectedFile, {
                header: true,
                worker: true,
                complete: (results) => dispatch(requestPlacesLocalizationProduction(parseGeoLocation(results.data)))
            });
        }
    }, [selectedFile]);

    if (tableList.size === 0 || fetchStatus === FETCH_STATUS.IN_PROGRESS) {
        return <Loader />;
    }

    return (
        <Container
            maxWidth="100vw"
            padding="1"
            border="1px"
            borderColor="gray.200"
            bgColor="white"
            display="flex"
            flexDirection="row"
        >
            <Flex justifyContent="flex-start">
                <Flex align="center" margin="1">
                    <Flex ml="150px">
                        <TableItemActionButton
                            panelType="Dodaj"
                        />
                    </Flex>
                    <Button
                        onClick={(e) => uploadCSVButtonRef.current.click(e)}
                        style={{ marginRight: '8px' }}
                    >
                        Załaduj plik CSV
                    </Button>
                    <input
                        accept=".csv"
                        style={{ display: 'none' }}
                        ref={uploadCSVButtonRef}
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <Button
                        onClick={() => dispatch(postChangeLogRequest(token, changelog))}
                        style={{
                            marginRight: '8px',
                            backgroundColor:
                                changelog.size === 0
                                    ? '#edf2f7'
                                    : 'lightgreen'
                        }}
                    >
                        Zapisz zmiany
                    </Button>
                </Flex>
                <Flex alignItems="center">
                    <Input
                        maxWidth="280px"
                        width="280px"
                        ml="4px"
                        placeholder="Filtr wyszukiwania"
                        onChange={(e) => dispatch(setFilterValue(e.target.value))}
                        value={filterValue}
                    />
                </Flex>
                <Flex align="center" margin="1" minW="200px">
                    <TableName />
                </Flex>
            </Flex>
            <Flex
                justifyContent="flex-end"
                flexDirection="row"
                width="100%"
                marginRight="1rem"
            >
                <CSVDownloader
                    data={exportableData.toJS()}
                    type="button"
                    className="chakra-button css-taj3dd"
                    style={{ marginRight: '8px' }}
                    filename="soczewki"
                    bom
                    config={{}}
                >
                    Pobierz dane
                </CSVDownloader>
                <CSVDownloader
                    data={exportableAllData.toJS()}
                    type="button"
                    className="chakra-button css-taj3dd"
                    style={{ marginRight: '8px' }}
                    filename="soczewkiWszystkieDane"
                    bom
                    config={{}}
                >
                    Pobierz wszystkie dane
                </CSVDownloader>
            </Flex>
        </Container>
    );
};
