import {
    Flex,
    Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import map from '../../../../assets/icons/map.png';
import { MapBoxDisplay } from './MapBoxDisplay';

export const MapPopover = ({
    lat, long, pinSrc, fullName, columnName
}) => (
    <Flex flexDirection="row">
        <Popover placement="left">
            <PopoverTrigger>
                <Flex
                    width="2rem"
                    minWidth="2rem"
                    height="2rem"
                    mr="1rem"
                    cursor="pointer"
                >
                    <img src={map} alt="Mapa" />
                </Flex>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{fullName}</PopoverHeader>
                <PopoverBody>
                    <MapBoxDisplay
                        lat={lat}
                        long={long}
                        pinSrc={pinSrc}
                    />
                </PopoverBody>
            </PopoverContent>
        </Popover>
        {columnName}
    </Flex>
);

MapPopover.propTypes = {
    columnName: PropTypes.string,
    fullName: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    pinSrc: PropTypes.string
};

MapPopover.defaultProps = {
    columnName: '',
    fullName: '',
    lat: '',
    long: '',
    pinSrc: ''
};
