import { combineReducers } from 'redux';
import { TABLE_REDUCER_NAME } from './containers/mainPage/table/const';
import { tableReducer } from './containers/mainPage/table/reducer';
import { navigationPanelReducer } from './containers/mainPage/navigationPanel/reducer';
import { NAVIGATION_PANEL_REDUCER_NAME } from './containers/mainPage/navigationPanel/const';
import { TOKEN_REDUCER_NAME } from './token/const';
import { tokenReducer } from './token/reducer';
import { LOGIN_REDUCER_NAME } from './containers/loginPanel/const';
import { loginReducer } from './containers/loginPanel/reducer';

export default function createReducer() {
    return combineReducers({
        [TABLE_REDUCER_NAME]: tableReducer,
        [NAVIGATION_PANEL_REDUCER_NAME]: navigationPanelReducer,
        [TOKEN_REDUCER_NAME]: tokenReducer,
        [LOGIN_REDUCER_NAME]: loginReducer
    });
}
