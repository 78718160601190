import {
    SELECT_TABLE, SET_DATA, UPDATE_CHANGELOG, CHANGE_PAGE, REMOVE_FROM_CHANGELOG, UPDATE_CHANGELOG_REQUEST,
    SET_SORT_OPTIONS, SET_NEW_TABLE_DATA, SET_FILTER_VALUE
} from './const';

export const setData = (data) => ({
    type: SET_DATA,
    data
});

export const setNewTableData = (newTableData) => ({
    type: SET_NEW_TABLE_DATA,
    newTableData
});

export const selectTable = (table) => ({
    type: SELECT_TABLE,
    table
});

export const updateChangelog = (tableItem, operation) => ({
    type: UPDATE_CHANGELOG,
    tableItem,
    operation
});

export const removeFromChangelog = (id) => ({
    type: REMOVE_FROM_CHANGELOG,
    id
});

export const changePage = (pageNumber) => ({
    type: CHANGE_PAGE,
    pageNumber
});

export const updateChangelogRequest = (tableItem, operation) => ({
    type: UPDATE_CHANGELOG_REQUEST,
    tableItem,
    operation
});

export const setSortOptions = (sortOrderKey, changeType) => ({
    type: SET_SORT_OPTIONS,
    sortOrderKey,
    changeType
});

export const setFilterValue = (filterValue) => ({
    type: SET_FILTER_VALUE,
    filterValue
});
