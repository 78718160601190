import {
    REQUEST_USER_LOGIN, SET_USER_ID, FAIL_USER_LOGIN
} from './const';

export const requestUserLogin = ({ username, password }) => ({
    type: REQUEST_USER_LOGIN,
    password,
    username
});

export const setUserId = (id) => ({
    type: SET_USER_ID,
    id
});

export const failUserLogin = () => ({
    type: FAIL_USER_LOGIN
});
