import styled from 'styled-components';

export const FormikPinImage = styled.img`
    margin-top: 8px;
    margin-left: 40%;
    aspect-ratio: 0.64102564102;
    width: 60px;
    height: 80px;
    border: 1px solid black;
    padding: 4px;
`;
