import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMapGL, { Marker } from 'react-map-gl';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { mapStyle } from '../const';
import pin from '../../../../assets/icons/pin.png';
import { PinImage } from './PinImage';

const MapBoxWrapper = styled.div`
    max-width: 300px;
    max-height: 200px;
    width: 300px;
    height: 200px;
`;

export const MapBoxDisplay = ({ lat, long, pinSrc }) => {
    const [viewport, setViewport] = useState({
        latitude: lat,
        longitude: long,
        zoom: 10
    });

    if (isEmpty(lat) || isEmpty(long)) {
        return null;
    }

    return (
        <MapBoxWrapper>
            <ReactMapGL
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                latitude={viewport.latitude}
                longitude={viewport.longitude}
                zoom={viewport.zoom}
                mapStyle={mapStyle}
                width="100%"
                height="100%"
                onViewportChange={(newViewport) => setViewport(newViewport)}
            >
                <Marker
                    longitude={long}
                    latitude={lat}
                >
                    <PinImage
                        src={isNil(pinSrc) || isEmpty(pinSrc) ? pin : pinSrc}
                        isOnMap
                    />
                </Marker>
            </ReactMapGL>
        </MapBoxWrapper>
    );
};

MapBoxDisplay.propTypes = {
    lat: PropTypes.number,
    long: PropTypes.number,
    pinSrc: PropTypes.string
};

MapBoxDisplay.defaultProps = {
    lat: '',
    long: '',
    pinSrc: ''
};
