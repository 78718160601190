import { keys } from 'ramda';

export const getInitialValues = (tableItem, columnNames) => {
    if (tableItem === undefined) {
        return Object.fromEntries(columnNames.map((key) => [key, '']));
    }

    const item = tableItem.get('data').toJS();
    return Object.fromEntries(keys(item).map((key) => (item[key] === null) ? [key, ''] : [key, item[key]]));
};
