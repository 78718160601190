import Pagination from '@atlaskit/pagination';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { range } from 'ramda';
import { changePage } from '../actions';

export const TablePagination = ({ numberOfPages }) => {
    const numberOfPagesArray = range(1, numberOfPages + 1);

    const dispatch = useDispatch();

    const onChange = (_, page) => {
        dispatch(changePage(page));
    };

    if (numberOfPages < 2) {
        return null;
    }

    return (
        <Flex padding="10px">
            <Pagination pages={numberOfPagesArray} onChange={onChange} />
        </Flex>
    );
};

TablePagination.propTypes = {
    numberOfPages: PropTypes.number.isRequired
};
