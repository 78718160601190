import {
    assoc, curry, invertObj, isEmpty, isNil, keys, omit, prop, reduce
} from 'ramda';
import { fromJS } from 'immutable';

export const SELECT_TABLE = 'table/SELECT_TABLE';
export const TABLE_REDUCER_NAME = 'Table';
export const SET_DATA = 'table/SET_DATA';
export const UPDATE_CHANGELOG = 'table/UDPATE_CHANGELOG';
export const SET_CSV_CHANGELOG = 'table/SET_CSV_CHANGELOG';
export const CHANGE_PAGE = 'table/CHANGE_PAGE';
export const REMOVE_FROM_CHANGELOG = 'table/REMOVE_FROM_CHANGELOG';
export const UPDATE_CHANGELOG_REQUEST = 'table/UPDATE_CHANGELOG_REQUEST';
export const SET_SORT_OPTIONS = 'table/SET_SORT_OPTIONS';
export const SET_NEW_TABLE_DATA = 'table/SET_NEW_TABLE_DATA';
export const SET_FILTER_VALUE = 'table/SET_FILTER_VALUE';
export const NUMBER_OF_ITEMS = 10;

export const SORT_ORDERS = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
};

export const SORT_CHANGE_TYPES = {
    SWAP: 'SWAP',
    SET: 'SET'
};

const swapOrder = (currentOrder) => currentOrder === SORT_ORDERS.ASCENDING
    ? SORT_ORDERS.DESCENDING
    : SORT_ORDERS.ASCENDING;

export const handleSortChangeType = (sortChangeType, currentOrder) => sortChangeType === 'SET'
    ? SORT_ORDERS.ASCENDING
    : swapOrder(currentOrder);

export const DEFAULT_EMPTY_DB_RECORD = {
    canonical_name: '',
    phone: '',
    label: '',
    name: '',
    secondary_name: '',
    address: '',
    city: '',
    postal_code: '',
    email: ''
};

export const COLUMN_TRANSLATION_TABLE = {
    canonical_name: 'Pełna nazwa',
    phone: 'Numer telefonu',
    id: 'ID',
    label: 'Kampania',
    name: 'Nazwa',
    secondary_name: 'Nazwa C.D',
    address: 'Adres',
    city: 'Miasto',
    postal_code: 'Kod pocztowy',
    country_code: 'Kraj',
    email: 'E-mail',
    custom_pin: 'Rodzaj pinezki',
    geo_lat: 'Szerokość geograficzna',
    geo_long: 'Wysokość geograficzna',
    geolocation: 'Położenie geograficzne',
    icon: 'Ikona',
    last_update: 'Czas ostatniej edycji'
};

const ifEmptySetNull = (value) => isEmpty(value) ? null : value;

const isEmptyOrNil = (value) => isEmpty(value) || isNil(value);

export const tableItemPropsToDefaultValues = (tableItem) => ({
    ...tableItem,
    canonical_name: isEmptyOrNil(tableItem.canonical_name)
        ? `${tableItem.name} ${tableItem.city} ${tableItem.address}`
        : tableItem.canonical_name,
    secondary_name: ifEmptySetNull(tableItem.secondary_name),
    country_code: isEmpty(tableItem.country_code) ? 'PL' : tableItem.country_code,
    email: ifEmptySetNull(tableItem.email),
    custom_pin: ifEmptySetNull(tableItem.custom_pin),
    icon: isEmpty(tableItem.icon) ? 'default' : tableItem.icon,
    last_update: Math.floor(Date.now() / 1000)
});

export const KEY_ORDERS = {
    last_update: 0,
    canonical_name: 1,
    label: 2,
    phone: 3,
    address: 4,
    city: 5,
    postal_code: 6,
    secondary_name: 7,
    email: 8,
    name: 9,
    custom_pin: 10,
    geo_lat: 11,
    geo_long: 12,
    icon: 13,
    id: 14,
    country_code: 15,
    geolocation: 16
};

export const KEY_FORM_ORDERS = {
    name: 1,
    secondary_name: 2,
    address: 3,
    city: 4,
    postal_code: 5,
    label: 6,
    phone: 7,
    email: 8,
    custom_pin: 9,
    icon: 10,
    geo_lat: 11,
    geo_long: 12,
    last_update: 12,
    canonical_name: 13,
    id: 14,
    country_code: 15,
    geolocation: 16
};

export const timestampsToData = (unixTimestamp) => {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleString();
};

export const CSV_COLUMNS_ORDERS = {
    ID: 1,
    NAZWA: 2,
    NAZWA2: 3,
    ADRES: 4,
    MIASTO: 5,
    'KOD POCZTOWY': 6,
    KRAJ: 7,
    TELEFON: 7,
    'E-MAIL': 8,
    IKONA: 9,
    'INNA PINEZKA': 10,
    'Pełna nazwa': 2,
    'Numer telefonu': 9,
    Kampania: 3,
    Nazwa: 4,
    'Nazwa C.D': 5,
    Adres: 6,
    Miasto: 7,
    'Kod pocztowy': 8,
    Kraj: 11,
    'E-mail': 10,
    'Rodzaj pinezki': 12,
    'Szerokość geograficzna': 13,
    'Wysokość geograficzna': 14,
    'Położenie geograficzne': 14,
    Ikona: 15,
    'Czas ostatniej edycji': 17
};

export const CSV_COLUMN_NAMES_INTERPRETATION = {
    ADRES: 'address',
    'KOD POCZTOWY': 'postal_code',
    'E-MAIL': 'email',
    IKONA: 'icon',
    KRAJ: 'country_code',
    MIASTO: 'city',
    NAZWA: 'name',
    NAZWA2: 'secondary_name',
    TELEFON: 'phone',
    'INNA PINEZKA': 'custom_pin',
    ID: 'id'
};

const renameKeys = curry(
    (keysMap, obj) => reduce(
        (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
        {},
        keys(obj)
    )
);

export const parseCSV = (row) => omit([''], renameKeys(CSV_COLUMN_NAMES_INTERPRETATION, row));

const getReversedCSVColumnNames = (isAllData) => isAllData ? COLUMN_TRANSLATION_TABLE : invertObj(CSV_COLUMN_NAMES_INTERPRETATION);

export const tableElementToCSVValue = (element, isAllData = false) => {
    const reversedCSVColumnNames = getReversedCSVColumnNames(isAllData);
    return reduce(
        (newElement, tableElementKey) => newElement.set(
            reversedCSVColumnNames[tableElementKey],
            element.get(tableElementKey)
        ),
        fromJS({}),
        keys(reversedCSVColumnNames)
    ).sortBy((v, k) => prop(k, CSV_COLUMNS_ORDERS));
};

export const mapStyle = 'mapbox://styles/mapbox/outdoors-v10';
