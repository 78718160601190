import {
    GET_TABLE_LIST,
    POST_CHANGELOG_REQUEST,
    PRODUCE_LOCATED_PLACES_REQUEST,
    SAVE_LOCATED_PLACES,
    SET_CHANGELOG_SUCCESS_STATUS, SET_ERRORS_MESSAGES,
    SET_TABLE_LIST
} from './const';
import { SET_CSV_CHANGELOG } from '../table/const';

export const getTableListRequest = () => ({
    type: GET_TABLE_LIST
});

export const setTableList = (tableList) => ({
    type: SET_TABLE_LIST,
    tableList
});

export const requestPlacesLocalizationProduction = (listOfElements) => ({
    type: PRODUCE_LOCATED_PLACES_REQUEST,
    listOfElements
});

export const saveLocatedPlaces = (placesWithGeoLocation) => ({
    type: SAVE_LOCATED_PLACES,
    placesWithGeoLocation
});

export const postChangeLogRequest = (token, changelog) => ({
    type: POST_CHANGELOG_REQUEST,
    token,
    changelog
});

export const setChangelogSuccessStatus = () => ({
    type: SET_CHANGELOG_SUCCESS_STATUS
});

export const setErrorsMessages = (errors) => ({
    type: SET_ERRORS_MESSAGES,
    errors
});

export const setCSVChangelog = (CSVChangelog) => ({
    type: SET_CSV_CHANGELOG,
    CSVChangelog
});
