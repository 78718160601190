import React from 'react';
import { Container, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Footer = ({ currentPage }) => (
    <Container maxW="100vw" backgroundColor="white" position="fixed" bottom="0" boxShadow="xs">
        <Text color="gray" p="4">
            {`Lenses panel - ${currentPage}`}
        </Text>
    </Container>
);

Footer.propTypes = {
    currentPage: PropTypes.string
};

Footer.defaultProps = {
    currentPage: ''
};
