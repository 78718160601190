import {
    all, filter, find, identity, isEmpty, isNil, keys, map as RMap, prop, propEq
} from 'ramda';
import { nanoid } from 'nanoid';
import { ID_LENGTH } from '../../../const';
import { tableItemPropsToDefaultValues } from '../table/const';

export const NAVIGATION_PANEL_REDUCER_NAME = 'NavigationPanel';
export const GET_TABLE_LIST = 'navigationPanel/GET_TABLE_LIST';
export const SET_TABLE_LIST = 'navigationPanel/SET_TABLE_LIST';
export const PRODUCE_LOCATED_PLACES_REQUEST = 'navigationPanel/PRODUCE_LOCATED_PLACES_REQUEST';
export const SAVE_LOCATED_PLACES = 'navigationPanel/SAVE_LOCATED_PLACES';
export const POST_CHANGELOG_REQUEST = 'navigationPanel/POST_CHANGELOG_REQUEST';
export const SET_CHANGELOG_SUCCESS_STATUS = 'navigationPanel/SET_CHANGELOG_SUCCESS_STATUS';
export const SET_ERRORS_MESSAGES = 'navigationPanel/SET_ERRORS_MESSAGES';

export const FETCH_STATUS = {
    DONE: 'DONE',
    IN_PROGRESS: 'IN_PROGRESS'
};

const parseGeoLatLong = (data) => {
    if (typeof data.geo_lat === 'string') {
        return ({
            ...data,
            geo_lat: parseFloat(data.geo_lat.replaceAll(',', '.')),
            geo_long: parseFloat(data.geo_long.replaceAll(',', '.'))
        });
    }
    return data;
};

const cmp = (a, b, key) => key === 'id'
    || key === 'geolocation'
    || (key === 'phone'
        ? a.replaceAll(' ', '') === b.replaceAll(' ', '')
        : (a === b
    || (isNil(a) && b === '')
    || (isNil(b) && a === '')
    || (!Number.isNaN(a) && parseInt(a, 10) === parseInt(b, 10))));

export const createCSVChangelog = (csvData, currentTable) => filter(
    (change) => !isNil(change),
    RMap(
        (place) => {
            if (isEmpty(place.id)) {
                return {
                    operation: 'ADD',
                    data: tableItemPropsToDefaultValues({
                        ...parseGeoLatLong(place),
                        id: nanoid(ID_LENGTH)
                    })
                };
            }
            const currentTablePlace = find((element) => propEq('id', place.id, element.data), currentTable);

            if (isNil(currentTablePlace)) {
                return null;
            }

            if (!isEmpty(place.id) && isEmpty(place.name)) {
                return {
                    operation: 'DELETE',
                    data: {
                        ...currentTablePlace.data,
                        last_update: Math.floor(Date.now() / 1000)
                    }
                };
            }

            const doesPropertiesMatch = all(identity, RMap(
                (key) => cmp(
                    prop(key, place),
                    prop(key, currentTablePlace.data),
                    key
                ),
                keys(place)
            ));

            if (!doesPropertiesMatch) {
                return ({
                    operation: 'EDIT',
                    data: tableItemPropsToDefaultValues({
                        ...parseGeoLatLong(place),
                        id: currentTablePlace.data.id,
                        geolocation: currentTablePlace.data.geolocation,
                        last_update: Math.floor(Date.now() / 1000)
                    })
                });
            }

            return null;
        },
        csvData
    )
);

export const parseGeoLocation = (placesList) => RMap((place) => parseGeoLatLong(place), placesList);
