import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { NAVIGATION_PANEL_REDUCER_NAME } from './const';

const getNavigationPanelReducerState = prop(NAVIGATION_PANEL_REDUCER_NAME);

export const tableSelector = createSelector(
    getNavigationPanelReducerState,
    (navigationPanelState) => navigationPanelState.get('table')
);

export const tableListSelector = createSelector(
    getNavigationPanelReducerState,
    (navigationPanelState) => navigationPanelState.get('tableList')
);

export const fetchStatusSelector = createSelector(
    getNavigationPanelReducerState,
    (navigationPanelState) => navigationPanelState.get('fetchStatus')
);
